@tailwind base;
@tailwind components;
@tailwind utilities;

/* react-pdf creates a hidden element but doesn't include the proper css to hide it */
/* https://github.com/wojtekmaj/react-pdf/issues/1815 */
.hiddenCanvasElement {
  display: none;
}

/* Overwrites base styles like we do in _base.scss */
p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

ol {
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

h1,
h2,
h3,
h4,
h5,
h5 {
  margin: 0;
  letter-spacing: 0.01rem;
}

a {
  color: inherit;
  text-decoration: inherit;
}

blockquote {
  display: initial;
  margin: 0;
}

input {
  letter-spacing: 0.01rem;
}

button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  letter-spacing: 0.01rem;
  cursor: pointer;
}
